<template>
  <v-menu
    v-model="openMenu"
    style="display: inline"
    offset-y
    right
    :close-on-content-click="false"
    min-width="200"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="ma-0" large icon v-on="on">
        <IconBox :tooltip="$t('common.sort')" color="iconButton">
          stem-sort
        </IconBox>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(option, index) in options"
        :key="index"
        @click="changeOrder(option)"
      >
        <v-list-item-title>
          <v-layout>
            {{ $t(`orderBy.${option}`) }}
            <v-spacer />
            <v-icon
              style="margin-left: 15px"
              color="textColor"
              v-show="orderBy.name === option"
              >{{ `fa-angle-${orderBy.desc ? "down" : "up"}` }}</v-icon
            >
          </v-layout>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import MenuMixin from "@/mixins/menuMixin.js";

export default {
  mixins: [MenuMixin],
  props: {
    options: Array,
    value: Object,
  },
  computed: {
    orderBy: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    changeOrder(option) {
      const order = { ...this.orderBy };
      if (order.name === option) {
        order.desc = !order.desc;
      } else {
        order.name = option;
      }

      this.orderBy = order;
    },
  },
};
</script>
