<template>
  <div v-if="items.length !== 0" class="tree-list-wrapper">
    <v-btn
      v-if="showExpandButton"
      text
      color="textColor"
      @click="onExpandAllToggle"
    >
      {{
        this.isExpandedAllItems ? $t("common.callapse") : $t("common.expand")
      }}
    </v-btn>
    <!-- <v-btn text color="textColor" @click="onSelectAllToggle">
      {{ this.isAllSelected ? "Odznacz" : "Zaznacz" }}
    </v-btn> -->
    <v-treeview
      open-on-click
      class="font-weight-light"
      selected-color="fields"
      :items="items"
      :value="selectedItems"
      :open="openedItems"
      :selectable="selectable"
      :disabled="disabled"
      item-disabled="locked"
      @update:open="onOpenedItemsChange"
      @input="onSelectedItemsChange"
    />
  </div>
</template>

<script>
export default {
  name: "TreeList",
  props: {
    showExpandButton: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    values: {
      type: Array,
      default: () => {
        return [];
      },
    },
    nodes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      items: [],
      selectedItems: [],
      openedItems: [],
    };
  },
  mounted() {
    this.mapNodesToTreeItems(this.nodes);
    this.setSelectedItems(this.values);
  },
  methods: {
    mapNodesToTreeItems(nodeItems) {
      const items = [];

      nodeItems.forEach((node) => {
        items.push({
          id: node.id,
          name: node.name,
          parentId: node.parentId,
          locked: node.locked,
          children: node.children.map((children) => {
            return {
              id: `${node.id}_${children.id}`,
              name: children.name,
              locked: children.locked,
            };
          }),
        });
      });

      this.items = items;
    },
    getParentNode(childId) {
      const parentNode = this.items.find((node) =>
        node.children.some((child) => child.id.split("_")[1] === childId)
      );

      return parentNode;
    },
    setSelectedItems(newValues) {
      const valuesWithParentId = [];

      newValues.forEach((value) => {
        const parentNode = this.getParentNode(value);

        if (parentNode) {
          valuesWithParentId.push(`${parentNode.id}_${value}`);
        }
      });

      this.selectedItems = valuesWithParentId;
    },
    onOpenedItemsChange(newOpenedItems) {
      this.openedItems = newOpenedItems;
    },
    onSelectedItemsChange(newSelectedItems) {
      const selectedChildrenIds = newSelectedItems.map(
        (selectedItem) => selectedItem.split("_")[1]
      );

      this.$emit("input", selectedChildrenIds);
    },
    onExpandAllToggle() {
      if (this.isExpandedAllItems) {
        this.openedItems = [];
      } else {
        this.openedItems = this.items.map((item) => item.id);
      }
    },
  },
  computed: {
    isExpandedAllItems() {
      return this.openedItems.length === this.items.length;
    },
  },
  watch: {
    values(newValues) {
      this.setSelectedItems(newValues);
    },
    nodes(newNodes) {
      this.mapNodesToTreeItems(newNodes);
      this.setSelectedItems(this.values);
    },
  },
};
</script>
