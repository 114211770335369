<template>
  <v-menu
    class="filters-menu"
    v-model="openMenu"
    style="display: inline"
    offset-y
    right
    bottom
    :close-on-content-click="false"
    transition="slide-y-transition"
    v-bind:content-class="isMobile ? 'mobile' : ''"
  >
    <template v-slot:activator="{ on }">
      <v-badge
        :value="filtersCount"
        right
        overlap
        :color="showRemoveFilters ? 'red' : 'badgeBackground'"
      >
        <template v-slot:badge>
          <div
            @mouseenter="showRemoveFilters = true"
            @mouseleave="showRemoveFilters = false"
          >
            <span v-show="!showRemoveFilters" class="badgeText--text">
              {{ filtersCount }}
            </span>
            <v-icon v-show="showRemoveFilters" small @click="removeFilters">
              fas fa-times
            </v-icon>
          </div>
        </template>
        <v-btn large icon text v-on="on">
          <IconBox :tooltip="$t('common.filters')" color="iconButton">
            stem-filter
          </IconBox>
        </v-btn>
      </v-badge>
    </template>

    <v-card>
      <v-layout class="layout-wrapper">
        <v-list
          class="filter-types-container"
          v-bind:class="isMobile && 'mobile'"
        >
          <v-list-item v-if="displaySearchField" class="search-text-field">
            <v-text-field
              clearable
              prepend-icon="stem-search"
              :label="$t('common.search')"
              single-line
              hide-details
              color="fields"
              v-model="searchValue"
            />
          </v-list-item>
          <v-list-item
            v-for="(category, index) in categories"
            :key="index"
            :class="{ active: selectedCategory === category }"
            @click="selectCategory(category)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ category.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action
              v-show="countFilters(selectedFilters[category.value])"
            >
              {{ countFilters(selectedFilters[category.value]) }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-spacer v-if="!this.isMobile"></v-spacer>
        <v-slide-x-transition>
          <v-layout
            v-if="selectedCategory"
            id="detailed-filters-list"
            v-bind:class="isMobile && 'mobile'"
          >
            <v-list
              style="min-width: 300px"
              class="active"
              v-if="selectedCategory.type === 'treeSelect'"
            >
              <v-layout
                v-bind:pl-4="!this.isMobile"
                v-bind:pr-4="!this.isMobile"
                align-center
                justify-start
              >
                <TreeList
                  selectable
                  showExpandButton
                  :nodes="selectedCategory.options"
                  :values="selectedFilters[selectedCategory.value]"
                  @input="
                    (selectedItems) =>
                      onFilterValueChange(selectedItems, selectedCategory.value)
                  "
                />
              </v-layout>
            </v-list>
            <v-list
              style="min-width: 200px"
              class="active"
              v-if="
                selectedCategory.type === 'multiSelect' ||
                selectedCategory.type === 'select'
              "
            >
              <v-list-item
                v-for="(option, index) in selectedCategory.options"
                :key="index"
                @click="selectFilter(selectedCategory, option)"
              >
                <v-list-item-action>
                  <v-checkbox
                    @click.prevent
                    :ripple="false"
                    color="fields"
                    :input-value="
                      selectedFilters[selectedCategory.value].includes(
                        option.value
                      )
                    "
                  ></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ option.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-list
              style="min-width: 200px"
              class="active"
              v-bind:content-class="isMobile && 'mobile'"
              two-line
              v-else-if="selectedCategory.type === 'datePicker'"
            >
              <v-list-item
                v-for="(option, index) in selectedCategory.options"
                :key="index"
                class="date-field-control"
              >
                <v-list-item-action>
                  <DateField
                    :label="option.name"
                    v-model="
                      selectedFilters[selectedCategory.value][option.value]
                    "
                    :endOfDay="option.endOfDay"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-list
              style="min-width: 200px"
              class="active"
              v-else-if="selectedCategory.type === 'chooser'"
            >
              <v-list-item class="chooser-field-control">
                <v-list-item-action>
                  <SelectWithAutocomplete
                    item-value="id"
                    :value="selectedFilters[selectedCategory.value]"
                    :items="selectedCategory.options.items"
                    :item-text="selectedCategory.options.displayName"
                    :name="$t('common.search')"
                    :multiselect="selectedCategory.options.multiselect"
                    @input="
                      (newValue) =>
                        onFilterValueChange(newValue, selectedCategory.value)
                    "
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-list
              style="min-width: 200px"
              class="active"
              v-else-if="selectedCategory.type === 'searcher'"
            >
              <v-list-item class="searcher-field-control">
                <v-list-item-action>
                  <div class="searcher-filter-field">
                    <CategoryField
                      ref="searcherFieldRef"
                      autocompleteFieldAsync
                      return-object
                      :edit="true"
                      :options="
                        (value) => {
                          return searchOptions(
                            value,
                            selectedCategory.options.searchFunc
                          );
                        }
                      "
                      :label="$t('common.search')"
                      :displayValueText="
                        (item) => selectedCategory.options.displayName(item)
                      "
                      :value="selectedFilters[selectedCategory.value]"
                      :hint="selectedCategory.options.hint"
                      @input="
                        (newValue) =>
                          onFilterValueChange(newValue, selectedCategory.value)
                      "
                    />
                    <div class="field-hint">
                      {{ $t("common.autoCompleteMinimum3WordsHint") }}
                    </div>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-list
              style="min-width: 200px"
              class="active"
              v-else-if="selectedCategory.type === 'tags'"
            >
              <v-list-item class="tag-search-field-control">
                <v-list-item-action>
                  <TagsSearcherField
                    :selectedValues="selectedFilters[selectedCategory.value]"
                    @change="
                      (newValue) =>
                        onFilterValueChange(newValue, selectedCategory.value)
                    "
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-list
              style="min-width: 200px"
              class="active"
              v-else-if="selectedCategory.type === 'bool'"
            >
              <v-list-item class="bool-field-control">
                <v-btn
                  text
                  color="textColor"
                  @click="
                    () => onFilterValueChange(null, selectedCategory.value)
                  "
                >
                  {{ $t("common.clear") }}
                </v-btn>
                <v-radio-group
                  :value="selectedFilters[selectedCategory.value]"
                  column
                  @change="
                    (newValue) =>
                      onFilterValueChange(newValue, selectedCategory.value)
                  "
                >
                  <v-radio
                    :label="$t('common.yes')"
                    :value="true"
                    color="fields"
                    :ripple="false"
                  ></v-radio>
                  <v-radio
                    :label="$t('common.no')"
                    :value="false"
                    color="fields"
                    :ripple="false"
                  ></v-radio>
                </v-radio-group>
              </v-list-item>
            </v-list>

            <v-list
              style="min-width: 200px"
              class="active"
              v-else-if="selectedCategory.type === 'organizations'"
            >
              <v-list-item class="organizations-field-control">
                <v-list-item-action>
                  <SelectWithAutocomplete
                    item-value="dictParamValueId"
                    name="Wybierz miasto"
                    :value="selectedFilters[selectedCategory.value].city"
                    :items="selectedCategory.options.items"
                    :item-text="selectedCategory.options.displayName"
                    @input="onCityChange"
                  />

                  <SelectWithAutocomplete
                    multiselect
                    item-value="dictParamValueId"
                    name="Wybierz numer boczny"
                    :disabled="!selectedFilters[selectedCategory.value].city"
                    :value="selectedFilters[selectedCategory.value].sideNumbers"
                    :items="childDictionaryItems"
                    :item-text="selectedCategory.options.displayName"
                    @input="onSideNumberChange"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-layout>
        </v-slide-x-transition>
      </v-layout>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import MenuMixin from "@/mixins/menuMixin.js";

import dictionaryParamsService from "@/services/DictionaryParamsService";

import DateField from "@/components/Fields/DateField";
import TreeList from "@/components/Shared/TreeList";
import SelectWithAutocomplete from "@/components/Basic/SelectWithAutocomplete";
import CategoryField from "@/components/Fields/CategoryField";
import TagsSearcherField from "@/components/Fields/TagsSearcherField";

export default {
  mixins: [MenuMixin],
  components: {
    TreeList,
    DateField,
    SelectWithAutocomplete,
    CategoryField,
    TagsSearcherField,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    value: Object,
    displaySearchField: {
      type: Boolean,
      default: false,
    },
    searchTextValue: {
      type: String,
    },
  },
  data() {
    return {
      selectedCategory: null,
      selectedFilters: { ...this.initFilters(), ...this.value },
      showRemoveFilters: false,
      childDictionaryItems: [],
    };
  },
  created() {
    if (
      this.selectedFilters.organization &&
      this.selectedFilters.organization.city
    ) {
      dictionaryParamsService
        .GetDictionaryValusByParentId(this.selectedFilters.organization.city)
        .then((response) => {
          this.childDictionaryItems = response;
        });
    }
  },
  methods: {
    removeFilters() {
      this.selectedFilters = this.initFilters();
      this.showRemoveFilters = false;
      this.searchValue = null;
      this.$emit("input", { ...this.selectedFilters });
    },
    selectFilter(category, option) {
      if (category.type === "select") {
        this.select(category, option);
      } else if (category.type === "multiSelect") {
        this.multiSelect(category, option);
      }
    },
    select(category, option) {
      if (this.selectedFilters[category.value] !== option.value) {
        this.selectedFilters[category.value] = option.value;
      } else {
        this.selectedFilters[category.value] = "";
      }
    },
    multiSelect(category, option) {
      const index = this.selectedFilters[category.value].indexOf(option.value);
      if (index !== -1) {
        this.selectedFilters[category.value].splice(index, 1);
      } else {
        this.selectedFilters[category.value].push(option.value);
      }
    },
    selectCategory(category) {
      this.selectedCategory =
        this.selectedCategory !== category ? category : null;
    },
    initFilters() {
      const filters = {};
      this.categories.forEach((c) => {
        switch (c.type) {
          case "treeSelect":
            filters[c.value] = [];
            break;
          case "multiSelect":
            filters[c.value] = [];
            break;
          case "select":
            filters[c.value] = "";
            break;
          case "datePicker":
            filters[c.value] = {};
            c.options.forEach((o) => (filters[c.value][o.value] = null));
            break;
          case "chooser":
            filters[c.value] = null;
            break;
          case "organizations":
            filters[c.value] = {
              city: null,
              sideNumbers: [],
            };
            break;
          default:
            filters[c.value] = null;
            break;
        }
      });

      if (this.categories.length === 1) {
        this.selectedCategory = this.categories[0];
      }
      return filters;
    },
    countFilters(category) {
      if (category != null) {
        switch (typeof category) {
          case "string":
            if (category !== "") {
              return 1;
            }
            return 0;
          case "object": {
            if (Array.isArray(category)) {
              return category.length;
            }
            if (category.id) {
              return 1;
            }
            let count = 0;
            Object.values(category).forEach((field) => {
              if (typeof field === "object") {
                if (Array.isArray(field)) {
                  count += field.length;
                }
              } else {
                if (field !== null) {
                  count++;
                }
              }
            });
            return count;
          }
          case "boolean":
            if (category != null) {
              return 1;
            }
            return 0;
          default:
            return 0;
        }
      }
      return 0;
    },
    onFilterValueChange(value, fieldType) {
      this.selectedFilters[fieldType] = value;
    },
    onCityChange(value) {
      this.selectedFilters[this.selectedCategory.value] = {
        city: value,
        sideNumbers: [],
      };

      if (value) {
        dictionaryParamsService
          .GetDictionaryValusByParentId(value)
          .then((response) => {
            this.childDictionaryItems = response;
          });
      }
    },
    onSideNumberChange(values) {
      this.selectedFilters[this.selectedCategory.value] = {
        city: this.selectedFilters[this.selectedCategory.value].city,
        sideNumbers: [...values],
      };
    },
    searchOptions(searchValue, searchFunction) {
      if (searchValue && searchValue.length >= 3) {
        this.$refs.searcherFieldRef.hideEmptyData = false;
        return searchFunction(searchValue);
      }

      this.$refs.searcherFieldRef.hideEmptyData = true;
      return Promise.resolve();
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    filtersCount() {
      let count = 0;

      Object.values(this.selectedFilters).forEach(
        (f) => (count += this.countFilters(f))
      );

      if (this.displaySearchField && this.searchValue) {
        count++;
      }

      return count;
    },
    isFilterSelected() {
      return this.filtersCount > 0;
    },
    searchValue: {
      get() {
        return this.searchTextValue;
      },
      set(newValue) {
        this.$emit("onSearchTextValueChange", newValue);
      },
    },
  },
  watch: {
    categories() {
      this.selectedFilters = { ...this.initFilters(), ...this.value };
    },
    selectedFilters: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
          this.$emit("input", { ...this.selectedFilters });
        }
      },
      deep: true,
    },
    value() {
      if (
        Object.entries(this.value).length === 0 &&
        this.value.constructor === Object
      ) {
        this.selectedFilters = { ...this.initFilters() };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-input.v-input--selection-controls.v-input--checkbox {
  margin: 0;
  padding: 0;
}
.v-input.v-input--selection-controls.v-input--checkbox
  ::v-deep
  .v-input--selection-controls__input {
  margin: 0;
}
.v-input.v-input--selection-controls.v-input--checkbox ::v-deep .v-icon {
  font-size: 20px;
}
.date-field-control {
  max-width: 280px;
}

.tag-search-field-control {
  .tags-searcher-field {
    width: 100%;
  }
}

.bool-field-control {
  display: flex;
  flex-direction: column;
  align-items: start;

  .v-radio:not(:last-child) {
    margin-bottom: 12px;
  }
}

.searcher-filter-field {
  width: 100%;
  display: flex;
  flex-direction: column;

  .field-hint {
    margin-left: 10px;
    margin-top: -15px;
    font-size: 14px;
  }
}

.organizations-field-control {
  .v-list-item__action .v-input {
    width: 100%;
  }
}

.organizations-field-control,
.tag-search-field-control,
.searcher-field-control,
.chooser-field-control {
  max-width: 350px;
  width: 350px;

  .v-list-item__action {
    width: 100%;
    margin-right: 0;
  }
}
.active {
  background-color: $content-section-background;
}
.v-menu__content {
  max-height: 80%;
}
@media screen and (max-width: 776px) {
  .layout-wrapper {
    flex-direction: column;
  }
}

.v-menu__content.mobile {
  min-width: 95% !important;
  max-width: 95% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
.filter-types-container {
  min-width: 200px;

  .search-text-field {
    margin-bottom: 20px;
  }
}
.filter-types-container.mobile {
  min-width: 105px;
}
::v-deep.filter-types-container.mobile #detailed-filters-list,
::v-deep.filter-types-container.mobile .v-list__tile {
  padding: 0 7px !important;
}
::v-deep#detailed-filters-list.mobile .v-list__tile__action,
::v-deep.filter-types-container.mobile .v-list__tile__action {
  min-width: auto;
}
::v-deep#detailed-filters-list.mobile .v-list {
  min-width: auto !important;
}
::v-deep#detailed-filters-list.mobile .v-treeview-node__content {
  overflow: hidden;
  flex: 1;
}
::v-deep#detailed-filters-list.mobile .v-treeview-node__label {
  flex-shrink: 1;
}
.v-menu__content.mobile .v-list.active {
  min-width: auto;
  flex: 1;
}
</style>
